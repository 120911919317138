import * as React from "react"

import Helmet from 'react-helmet'

import '../styles/main.css'

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  // backgroundColor: '#ffff33'
  // backgroundColor: '#fff'
  backgroundColor: '#dee'
}

const createHeadingTextShadow = () => {
  let shadows = [];
  const alpha = 1;
  // const color1 = '0, 0, 0';
  const color1 = '255, 0, 255';
  // const color1 = '255, 255, 51';
  // const color2 = '255, 255, 255';

  for (let i = 0; i < 30; i++) {
    const step = 0.01 * i;
    shadows.push(`-${step}em ${step}em rgba(${color1}, ${alpha})`)
  }

  // for (let i = 30; i < 32; i++) {
  //   const step = 0.01 * i;
  //   shadows.push(`-${step}em ${step}em rgba(${color2}, ${alpha})`)
  // }

  for (let i = 0; i < 4; i++) {
    const step = 0.01 * i
    shadows.push(`${step}em -${step}em rgba(${color1}, ${alpha})`);
    shadows.push(`-${step}em -${step}em rgba(${color1}, ${alpha})`);
    shadows.push(`${step}em ${step}em rgba(${color1}, ${alpha})`);
  }

  return shadows.join(',');
}
const headingStyles = {
  textShadow: createHeadingTextShadow(),
  fontSize: '5rem',
  color: '#dee',
  lineHeight: '85%',
  letterSpacing: '-0.1em'
}

const footerStyles = {}

// markup
const IndexPage = () => {
  return (<>
    <main style={pageStyles}
      className="flex items-center justify-center absolute inset-0">
      <Helmet>
        <title>IRIS Internet Services</title>
      </Helmet>
      <div className="text-center">
        <h1 style={headingStyles}
          className="mb-4 italic uppercase">
          <div style={{fontSize:'1.6em'}}>iris</div>
          <div style={{fontSize:'1.0em'}}>web</div>
          <div style={{fontSize:'1.0em'}}>.dev</div>
        </h1>
      </div>
    </main>
    <footer className="absolute bottom-0 left-0 right-0 text-xs pb-2"
      style={footerStyles}>
      <p className="text-center">
        IRIS Internet Services – mail: hello (a) irisweb.dev
      </p>
    </footer>
  </>)
}

export default IndexPage
